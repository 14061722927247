// esignature page
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import WebbDividerMedium from '../webb/webb-divider-md';
import WebbDividerSmall from '../webb/webb-divider-sm';
import WebbLoaderIcon from '../webb/webb-loader-icon';
import WebbDateTime from '../webb/webb-datetime';
import WebbSiteName from '../webb/webb-site-name';

import DocumentInfoModule from './esin-docx-info';
import UserEsinMailModule from './user-esin-emid';
import DocumentFileModule from './esin-docx-file';
import UserEsinDone from './user-esin-done';
import UserEsinWait from './user-esin-wait';

import { cmyk, degrees, grayscale, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import {encode,decode} from 'base64-arraybuffer'
import { ApproveInvoice, CallAzureRowColSign, GetBulkDocumentInfo, GetDocumentInfo, saveUserAction, SetDocumentInfo } from '../../services/srvc-esin-realm';

import AuthMailCustomModule from '../auth/auth-mail-custom'
import { CaptureBasicSignature, CaptureCustomSignature } from '../../services/srvc-utilities';

import pdf2base64 from 'pdf-to-base64';

export default function BulkDocumentViewModule () {

const history=useHistory()

  const {enid, usid} = useParams();
  const [data,setData]=useState()
  const [info, setInfo] = useState({});
  const [mmbr, setMember] = useState({});
  const [file, setFile] = useState(false);
  const [disable,setDisable]=useState(false)
   const [auth,setAuth]=useState(false)

  const [view,setView]=useState(true)
  const [initialview,setInitialView]=useState(true)

  const [loading, setLoading] = useState(true);
  const [submit,setSubmit] = useState(false)
  const [done,setDone] = useState(false)

  const [docload,setDocload]=useState(true)
  const [signed,setSigned]=useState(false)
  const [pgno,setPgno]=useState()
  const [docs, setDocs] = useState()

  
  const authuser=JSON.parse(localStorage.getItem("auth"))
  
  

  useEffect( () => {
    if (enid){
      setLoading(true)
      const fetchData = async() => {
        
        
        const docx = await GetBulkDocumentInfo({document:enid})
        var record = docx.data
        if(record==false)
        {
          setDocs(record)
          
          
        }

        if(record!= false)
        {
          if(record.file.length==0){setDocs(false); return}
          // console.log(record)
          setInfo(record)
          setData(record)
          setMember(record.list.find(item => item.enid === usid))
          var bs64 = await pdf2base64(record.file[0].link)
          setFile(bs64)
          setLoading(false);
        }
       
        
      }
      fetchData()
    } else {}
  },[enid, usid]);

  useEffect(() => {
    setLoading(true)
    if(data!=null && mmbr!=null) {
     
      const fetchInitialDocs=async() => {
        // console.log("in the initial file load")
        
        var bs64 = await pdf2base64(data.file[0].link)
        // console.log(bs64)
        for(var i=0;i<mmbr.locn.length;i++)
        {
          if(mmbr.locn[i].type=="allx")
          {
             bs64= await CaptureBasicSignature({"file":bs64, "locn":mmbr.locn[i], "name":mmbr.name})
          }
          if(mmbr.locn[i].type=="lstx")
          {
             bs64= await CaptureBasicSignature({"file":bs64, "locn":mmbr.locn[i], "name":mmbr.name})
          }
          if(mmbr.locn[i].type=="fstx")
          {
             bs64= await CaptureBasicSignature({"file":bs64, "locn":mmbr.locn[i], "name":mmbr.name})
          }
          if(mmbr.locn[i].type=="cstm")
          {
            bs64=await CaptureCustomSignature({"file":bs64,"locn":mmbr.locn[i],"name":mmbr.name})

          }
          setFile(bs64)

        }

      setFile(bs64)
      setLoading(false)
    }
    fetchInitialDocs()
  }
},[mmbr])


  const SetDocument = async (data) => {
    // console.log(pgno)
    var base64
    // console.log(data)
   
    if (data.stat ) {

      setDisable(true)
      setLoading(true)
      setInitialView(false)
      setSubmit(true)

      if(data.value!='')
      {
        // var temp=data.value.split("base64")
        // var temp1=temp[1].split(",")
        base64=data.value           
      }

      const datx = {
        member:mmbr,
        trxnid: enid,
        feat:{qrcd:info.feat.qrcd},
        doc_id: enid,
        typedname:data.name,
        method:"Signature Completed with Email + OTP",
        signpng:data.value!=''?base64:'',
        last: mmbr.rank === info.list.length ? true : false,
        base64: await pdf2base64(info.file[0].link),
        //webhookurl: 'https://webhook.site/74d0aaa2-c9c9-4f5d-970e-679fec9196e8',
        webhookurl:"https://ap-south-1.aws.data.mongodb-api.com/app/contrato-esin-rdujc/endpoint/hook/bulk/esin",
        baseurl:"https://contrato.ws" 
      }

      // console.log(datx)
      const res=await SetDocumentInfo(datx)
      // console.log(res)
      setSigned(true)
      setFile(res.data)
      setLoading(false)
      setView(info.feat.view==false?false:true)
      setDone(true)
      localStorage.clear(mmbr.usid)
   
    }
    
    else {
      // console.log ('Not Signed')
    }

  }

  console.log(docs)

  if(docs==false) return (<> <p  className='text-center text-danger mt-5 '>This Document is Not Ready</p><p  className='text-center text-primary' >Please Refresh page </p> <br/><br/><br/> <div style={{maxWidth:"120px", marginLeft:"auto", marginRight:"auto"}}> <button className='btn btn-outline-primary' onClick={()=> window.location.reload(true)}><i class="bi bi-arrow-clockwise"></i> Refresh</button> </div> </>)

  if (loading && !submit )  return ( <> <WebbLoaderIcon /> </> )
  if(!loading && data.stat == 11 && !data.actv ){return( <p  className='text-center text-danger mt-5 fw-bold'>This Document is Cancelled By User</p>)}
  //if(!loading && auth == false){ return(<h4 className='text-center text-danger mt-5 fw-bold'>Sorry.. You are not Authorised to Sign</h4>)}
  if(!loading && mmbr.stat == 1){return( <UserEsinDone />)}
  if(!loading && info.sort == '0123' && mmbr.rank!=1 && info.list[parseInt(mmbr.rank)-2].stat==0){return( <UserEsinWait />)}
  
  return (
  <> 

      {/* <div className={`${!loading && auth == false ? "":"d-none"}`}>
          <h4 className='text-center text-danger mt-5 fw-bold'>Sorry.. You are not Authorised to Sign</h4>
      </div> */}

      {/* <div className={`${!loading && data.stat == 11 && !data.actv ?"":"d-none"}`}>
          <p>This Document is Cancelled By User</p>
      </div> */}

      {/* <div className={`${!loading && mmbr.stat == 1?"":"d-none"}`}>
        <UserEsinDone />
      </div> */}

      {/* <div className={`${!loading && info.sort == '0123' && mmbr.rank!==1 && info.list[parseInt(mmbr.rank)-2].stat==0?"":"d-none"}`}>
        <UserEsinWait />
      </div> */}



      <div className={`${!loading && !submit && initialview && mmbr.stat!== 1 ?"":"d-none"}`}>
            <WebbDividerSmall />
            <DocumentInfoModule  data={enid} />
            <WebbDividerSmall />
            <UserEsinMailModule enable={disable} feature={info.feat} data={mmbr} esin={SetDocument}/>
            <DocumentFileModule signed={signed}  view={view} data={file}/>
            <WebbDividerSmall /> 
      </div> 

      <div className={`${loading && submit?"":"d-none"}`}>
        <WebbLoaderIcon />
        <h6 className='text-center'>Capturing Your Signature</h6>
      </div>

      <div className={`${!loading && submit && done?"":"d-none"}`}>
        {/* <DocumentFileModule signed={signed}  view={view} data={file}/> */}
      </div>

      <div className={`${!loading && submit && !done?"":"d-none"}`}>
        // failure while capturing signature
      </div>
      <WebbDividerSmall /> 

      {/* <WebbDateTime />
      <WebbSiteName /> */}
       
  </>
  )

}