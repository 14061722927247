import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { GetDocumentInfo, getKycTransactionData, saveKYCDocs, saveUserAction, uploadDocs, VerifyBusinessPAN, VerifyUserPAN } from '../../services/srvc-esin-realm';
import UserEsinDone from '../esin/user-esin-done';
import WebbLoaderIcon from '../webb/webb-loader-icon';
import BasicInfoModule from './basic-info';

const CollectKYCUDocsModule = () => {
    const [type,setType]=useState()
    const [file,setFile]=useState()
    const [data,setData]=useState([])
    const [id,setId]=useState('')
    const [member,setMember]=useState()
    const [loading,setLoading]=useState(true)
    const [upload,setUpload]=useState(false)
    const [business,setBusiness]=useState()

    const [resstatus,setResstatus]=useState('')

    const [text,setText] =useState('')
    

    const enid=useParams().enid
    const usid=useParams().usid

    // console.log(enid , "+", usid)

    const authuser=JSON.parse(localStorage.getItem("auth"))

    const history=useHistory()

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

    useEffect(()=>{

        const fetchdata= async()=>{
            setLoading(true)
            const docx = await getKycTransactionData({data:{enid:enid}})
            // console.log(docx.data)
            setData(docx.data)
           
            setLoading(false)
           
        }

        fetchdata()

    },[enid])

    if(data && data.stat=="6")
    {
      return(<> <UserEsinDone /> </>)
    }


const VerfiyDocs=async()=>{
    if(type=="" || file==null || id=="")
    {
        // window.alert("kindly fill All fields")
        setText("kindly fill All fields")
    }
    else
    {
        setLoading(true)

        setId(id.toUpperCase())

        var panno=id.toUpperCase()

        // console.log()

        if(panno =="ABCPU1234X")  // valid
        {

          var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded KYC Document for Verification with PAN no. ${panno} </p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
          await saveUserAction(JSON.stringify(dbj))

          var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name}'s KYC Document Verified With Name ${data.name} and PAN No. ${panno}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
          await saveUserAction(JSON.stringify(dbj))

          var result = {
              message: "Verification completed with positive result",
              upstreamName : data.name,
              verified: true
          }

          // console.log(result)
          setResstatus(result)

          setUpload(true)

        }

        if(panno == "ABCPU1234Y")  // negative
        {
          var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded KYC Document for Verification with PAN no. ${panno} </p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
          await saveUserAction(JSON.stringify(dbj))

          var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name}'s KYC Document Could Not Verified </p>`,"document":data.clnt.trid,date:new Date()},"usid":data.usid}
          await saveUserAction(JSON.stringify(dbj))

          
          var result = {
            message: "Verification completed with Negative result",
            upstreamName : data.name + "XYZ",
            verified: false
           }

            // console.log(result)
            setResstatus(result)

            setUpload(true)

        }

        if(panno == "ABCPU1234Z")  // failed
        {
          var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded KYC Document for Verification with PAN no. ${panno}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
          await saveUserAction(JSON.stringify(dbj))

          var result = {
            message: "Invalid Pan Card Number",
            upstreamName : "Invalid",
            verified: false
           }

            // console.log(result)
            setResstatus(result)

            setUpload(true)

        }




        if(panno!="ABCPU1234X" && panno != "ABCPU1234Y" && panno != "ABCPU1234Z"){

              var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded KYC Document for Verification with PAN no. ${panno}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
              await saveUserAction(JSON.stringify(dbj))

              const result= await VerifyUserPAN({number:panno,name:data.name})
              console.log(result)
            if(result.verified==true)
            {
              var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name}'s KYC Document Verified With Name ${result.upstreamName} and PAN No. ${panno}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
              await saveUserAction(JSON.stringify(dbj))
            }
            if(result.verified==false)
            {
              var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name}'s KYC Document Could Not Verified </p>`,"document":data.clnt.trid,date:new Date()},"usid":data.usid}
              await saveUserAction(JSON.stringify(dbj))
            }
          setResstatus(result)

          setUpload(true)

        }       
        
       
        
        
         setLoading(false)
    }
}

const UploadkycDocs=async()=>{
  setLoading(true)
  
  var userdata
  if(file!=null)
  {
    const str1=await getBase64(file)
    // console.log(str1)
    userdata={
      "file":str1,
      "type":"indx",
      "sort":type,
      
  }

    var payload={
      "data":{
        "file":userdata.file,
        "document":data.clnt.trid,
        "trxn":enid,
        "format":"kyc",
        "sort":"indx-panc",
        "data":{
          "name":data.name,
          "id":id,
          "verf":resstatus.verified,
          "vrnm":resstatus.upstreamName
          }

      },
      "user":data.admn[0].usid
    }

  // console.log(payload)
  const res= await saveKYCDocs(payload)
  //  console.log(res)
   
   history.push(`/`)
  }
}

const setStateData=async()=>{

  setUpload(true)
    UploadkycDocs()
 
 
}

if(loading){
  return (<> <WebbLoaderIcon /> </>)
}

  return(
      <>
    
      {
          upload?
          <>
                 <div className='mt-5 border p-5 bg-white' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"180px"}}>

                    <p className={`fw-bold text-center ${resstatus.verified==true?'text-success':'text-danger'}`}>{resstatus.verified==true?resstatus.message:resstatus.message}</p>
                    <p className='fw-bold text-center text-dark'>Name As Per ID:- {resstatus.upstreamName}</p>
                    <p className='text-center text-dark'>Signatory Name on Document will be:- {resstatus.upstreamName}</p>
                    <hr/>
                    
                    {
                      resstatus.verified==true?
                      <>
                      {/* <button onClick={()=>UploadkycDocs()} className='btn btn-primary mt-5 w-100'>Next</button> */}
                      <button onClick={()=>setStateData()} className='btn btn-primary mt-5 w-100'>Next</button>
                      </>:
                      <>
                      <button onClick={()=>{setUpload(false)}} className='btn btn-primary mt-5 w-100'>Go Back</button>
                      </>
                    }
                    
               </div> 
             
          </>
          :
          <>
          
            <>
              <div className='mt-5 border p-5 bg-white' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto"}}>
                    <h6 className='text-center mb-2'>{data.enid}</h6>

                    <BasicInfoModule data={data}  />

                    <hr className="mb-5"/>

                    <p className=' fw-bold text-center text-primary'>Upload Individual's Document For Verification</p>
                    <hr className="mb-5"/>
                    <div className='d-flex'>
                    <select onChange={(e)=>{setType(e.target.value);setText('')}} className='mx-1' style={{width:"50%"}}>
                        <option>Choose Document Type</option>
                        {/* <option value={"adhr"}>Adhaar Card</option>
                        <option value={"drvl"}>Drivery Liecence</option> */}
                        <option value={"panc"}>Pan Card</option>
                        {/* <option value={"othr"} >Other Govt. Card</option> */}
                    </select>
                    
                    <input accept='image/png , image/jpeg' onChange={(e)=>{setFile(e.target.files[0]);setText('')}} className='form-control' type={"file"} />
                    </div>
                    <input  className='form-control mt-3' type="text" value={id} onChange={(e)=>{setId(e.target.value);setText('')}}  placeholder="PAN Number"/>
                    
                    <div className='d-flex'>
                      <div className='me-auto'></div>
                      <div className='text-end'><button onClick={()=>setId("ABCPU1234X")} className='btn btn-sm btn-warning back-color-next border-none rounded-pill px-3 mt-3'>Use Test Credential</button></div>

                    </div>
                    <p className='mt-3 text-danger'>{text}</p>
                    <button onClick={()=>VerfiyDocs()} className='btn btn-primary mt-5 w-100'>Verify</button>
               </div> 
            </>
                
          </>
      }
          
      </>
  )
};

export default CollectKYCUDocsModule;
