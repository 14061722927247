// esignature page
import { Document,Page } from 'react-pdf';
import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import WebbLoaderIcon from '../webb/webb-loader-icon';
// import { StyleSheet, Page, Document} from '@react-pdf/renderer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;





export default function DocumentFileModule (props) {
  const data = props.data;
  const view= props.view
  const signed=props.signed

  // console.log(props)
  

  const [scale, setScale] = useState(1.29);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

 
  useEffect(()=>{
    if(data=="*****")
    {
      setLoading(true)
    }
    else{
      setLoading(false)
    }
   

  },[data])

  
  if (loading)  return (
  <>
  <div className="" style={{height:'24vh'}}></div>
    <div className="text-center py-1">
        <div class="spinner-border text-primary" role="status">
        </div>
      <p className="">Loading PDF</p>
    </div>
  
  </>
  )
  
  return (
  <>
   
    <div>
    <div className={`${view == true && signed == true ?"":"d-none"}`}>
          <div style={{maxWidth:"760px", marginLeft:"auto", marginRight:"auto", height:"100px", backgroundColor:"#C1FFAE"}}>
            <h4 className='text-center text-dark my-auto mt-3 p-3'>Congratulations...</h4>
            <h6 className='text-center text-dark mt-1' >Your signature has been captured.</h6>
          </div>
    </div>

    <div className={`${view == true  ?"":"d-none"}`}>
      <div className='border border-dark mt-4'>
        <embed 
        style={{width:"100%",height:"100vh"}} 
        src={`data:application/pdf;base64,${data}`+ "#toolbar=0"} 
        type="application/pdf" 
        />
      </div>

    </div>   
    </div>


  </>
  )
}