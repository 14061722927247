import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { GetDocumentInfo, saveUserAction, uploadDocs, VerifyBusinessPAN, VerifyUserPAN } from '../../services/srvc-esin-realm';
import WebbLoaderIcon from '../webb/webb-loader-icon';

const UploadAadhaarKycModule = () => {
    const [type,setType]=useState()
    const [file,setFile]=useState()
    const [data,setData]=useState([])
    const [id,setId]=useState('')
    const [member,setMember]=useState()
    const [loading,setLoading]=useState(true)
    const [upload,setUpload]=useState(false)
    const [compkyc,setCompkyc]=useState(false)
    const [compfile,setCompfile]=useState()
    const [comppan,setComppan]=useState()
    const [compname,setCompname]=useState()
    const [compres,setCompres]=useState()
    const [hide,setHide]=useState(false)
    const [final,setFinal]=useState(false)

    const [resstatus,setResstatus]=useState('')
    

    const enid=useParams().enid
    const usid=useParams().usid

    const authuser=JSON.parse(localStorage.getItem("auth"))

    const history=useHistory()

    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

      useEffect(()=>{
        setLoading(true)
        const fetchdata=async()=>{
          const docx = await GetDocumentInfo({enid:enid,mode:"adhr"})
            // console.log(docx)
            setData(docx)
            setMember (docx.list.find(item => item.enid === usid))
            var mem=docx.list.find(item => item.enid === usid)
            if(authuser==null && docx.feat.auth==true)
            {  
                history.push(`/auth/docs/adhr/${enid}/${usid}`)
            }
            if(docx.admn[0].usid===mem.usid)
            {
              window.location.href=mem.sinz
            }
            else{

              window.location.href=mem.sinz

                // if(docx.feat.kycu==true && docx.feat.kycb==false){
                //   setHide(false)
                //   setCompkyc(false)
                //   setLoading(false)
                // }
                // if(docx.feat.kycb==true && docx.feat.kycu==false)
                // {
                //   setCompkyc(true)
                //   setHide(true)
                //   setLoading(false)
                // }
                // if( docx.feat.kycb==true && docx.feat.kycu==true){
                //   console.log("both is true")
                //   setHide(false)
                //   setCompkyc(false)
                //   setLoading(false)
                // }
                // if(docx.feat.kycu==false && docx.feat.kycb==false){
                //    // history.push(`/esma/docs/${enid}/${usid}`) 
                //    setLoading(true)
                //    var mem=docx.list.find(item => item.enid === usid)
                //    window.location.href=mem.sinz
                   
                // }
            }
            
         
          
        }
        fetchdata()
    
      },[enid])


const VerfiyDocs=async()=>{
    if(type=="" || file==null || id=="")
    {
        window.alert("kindly fill All fields")
    }
    else
    {
        setLoading(true)
                
        var obj={data:{memo:`${member.name} Uploaded KYC Document for Verification with PAN no. ${id}`,"document":data.clnt.trid,date:new Date()},"user":member.usid}
        await saveUserAction(JSON.stringify(obj))

        const result= await VerifyUserPAN({nmbr:id,name:member.name})
        //console.log(result)
       if(result.code==200)
       {
        var obj={data:{memo:`${member.name}'s KYC Document Verified With Name ${result.data} and PAN No. ${id}`,"document":data.clnt.trid,date:new Date()},"user":member.usid}
        await saveUserAction(JSON.stringify(obj))
       }
       if(result.code!=200)
       {
        var obj={data:{memo:`${member.name}'s KYC Document Could Not Verified`,"document":data.clnt.trid,date:new Date()},"user":member.usid}
        await saveUserAction(JSON.stringify(obj)) 
      }
          setResstatus(result)

          setUpload(true)
       
        
        
         setLoading(false)
    }
}
const VerfiyCompDocs=async()=>{
  if(type=="" || compfile==null || compname=="")
  {
      window.alert("kindly fill All fields")
  }
  else
  {
      setLoading(true)      
      var obj={data:{memo:`${member.name} Uploaded Company KYC Document for Verification with Company PAN no. ${comppan} and Company Name ${compname}`,"document":data.clnt.trid,date:new Date()},"user":member.usid}
      await saveUserAction(JSON.stringify(obj))
      
      const result= await VerifyBusinessPAN({nmbr:comppan,name:member.name})
      //console.log(result)
      if(result.code==200)
      {
       var obj={data:{memo:`${member.name} Company KYC Document Verified With Company Name ${result.data} and Company PAN No. ${comppan} `,"document":data.clnt.trid,date:new Date()},"user":member.usid}
       await saveUserAction(JSON.stringify(obj))
      }
      if(result.code!=200)
      {
       var obj={data:{memo:`${member.name} Company KYC Document Could Not Verified`,"document":data.clnt.trid,date:new Date()},"user":member.usid}
       await saveUserAction(JSON.stringify(obj))
      }
     
        setCompres(result)
        
      setFinal(true)
      setCompkyc(false)
      setUpload(false)
       setLoading(false)
  }
}

const UploadkycDocs=async()=>{
  setLoading(true)
  
  var compndata
  var userdata
  if(compfile!=null)
  {
    const str2=await getBase64(compfile)
    compndata={
      "file":str2.split(";base64,").pop(),
      "type":type,
      "name":compname,
      "id":comppan,
      "verf":true,
      "vrnm":compres.data
     }
  }
  if(file!=null)
  {
    const str1=await getBase64(file)
    userdata={
      "file":str1.split(";base64,").pop(),
      "type":type,
      "name":member.name,
      "id":id,
      "verf":true,
      "vrnm":resstatus.data
      }
  }
  
  var payload={
    "enid":data.clnt.trid,
    "usid":member.usid,
    "user":userdata?userdata:null,
    "comp":compndata?compndata:null,
}
  console.log(payload)
  const res= await uploadDocs(payload)
  //  console.log(res)
   
  // history.push(`/esma/docs/${enid}/${usid}`)
  window.location.href=member.sinz
}

const setStateData=async()=>{

  setUpload(false)
  if(data.feat.kycb==true)
  {
    setCompkyc(true)
  }
  else{
    UploadkycDocs()
  }
  // 
  setHide(true)
  // 
 
}

if(loading){
  return (<> <WebbLoaderIcon /> </>)
}

  return(
      <>
      {
        final?
        <>
         <div className='mt-5 border p-5' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"180px"}}>

            <p className={`fw-bold text-center ${compres.code==200?'text-success':'text-danger'}`}>{compres.code==200?"Verification Completed SuccessFully":"Wrong Credential... Verification UnsuccessFull"}</p>
            <p className='fw-bold text-center text-dark'>Company Name As Per ID:- {compres.data}</p>
            <p className='text-center text-dark'>Company Name on Document will be:- {compres.data}</p>
            <hr/>

            {
              compres.code=="200"?
              <>
               <button onClick={()=>UploadkycDocs()} className='btn btn-primary mt-5 w-100'>Next</button> 
              
              </>:
              <>
              <button onClick={()=>{setCompkyc(false);setFinal(false)}} className='btn btn-primary mt-5 w-100'>Go Back</button>
              </>
            }

            </div>
        </>
        :
        <>
        </>
      }
      {
        compkyc?
        <>
        <div className='mt-5 border p-5' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"350px"}}>
                    <p className=' fw-bold text-center text-primary'>Upload Company's Document For Verification</p>
                    <hr/>
                    <div className='d-flex'>
                    <select onChange={(e)=>setType(e.target.value)} className='mx-1' style={{width:"50%"}}>
                        <option>Choose Document Type</option>
                        {/* <option value={"adhr"}>Adhaar Card</option>
                        <option value={"drvl"}>Drivery Liecence</option> */}
                        <option value={"panc"}>Pan Card</option>
                        {/* <option value={"othr"} >Other Govt. Card</option> */}
                    </select>
                    
                    <input onChange={(e)=>setCompfile(e.target.files[0])} className='form-control' type={"file"} />
                    </div>
                    <input value={comppan} onChange={(e)=>setComppan(e.target.value)} className='form-control mt-3' type={"text"} placeholder="Company PAN Number" />
                    <input  className='form-control mt-3' type="text" value={compname} onChange={(e)=>setCompname(e.target.value)}  placeholder="Company Name"/>
                    <button onClick={()=>VerfiyCompDocs()} className='btn btn-primary mt-5 w-100'>Verify</button>
               </div> 
        </>:
        <>
        </>

      }

      {
          upload?
          <>
                 <div className='mt-5 border p-5' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"180px"}}>

                    <p className={`fw-bold text-center ${resstatus.code==200?'text-success':'text-danger'}`}>{resstatus.code==200?"Verification Completed SuccessFully":"Wrong Credential... Verification UnsuccessFull"}</p>
                    <p className='fw-bold text-center text-dark'>Name As Per ID:- {resstatus.data}</p>
                    <p className='text-center text-dark'>Signatory Name on Document will be:- {resstatus.data}</p>
                    <hr/>
                    
                    {
                      resstatus.code=="200"?
                      <>
                      {/* <button onClick={()=>UploadkycDocs()} className='btn btn-primary mt-5 w-100'>Next</button> */}
                      <button onClick={()=>setStateData()} className='btn btn-primary mt-5 w-100'>Next</button>
                      </>:
                      <>
                      <button onClick={()=>{setUpload(false)}} className='btn btn-primary mt-5 w-100'>Go Back</button>
                      </>
                    }
                    
               </div> 
             
          </>
          :
          <>
          {
            hide==false?
            <>
              <div className='mt-5 border p-5' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"300px"}}>
                    <p className=' fw-bold text-center text-primary'>Upload Individual's Document For Verification</p>
                    <hr/>
                    <div className='d-flex'>
                    <select onChange={(e)=>setType(e.target.value)} className='mx-1' style={{width:"50%"}}>
                        <option>Choose Document Type</option>
                        {/* <option value={"adhr"}>Adhaar Card</option>
                        <option value={"drvl"}>Drivery Liecence</option> */}
                        <option value={"panc"}>Pan Card</option>
                        {/* <option value={"othr"} >Other Govt. Card</option> */}
                    </select>
                    
                    <input onChange={(e)=>setFile(e.target.files[0])} className='form-control' type={"file"} />
                    </div>
                    <input  className='form-control mt-3' type="text" value={id} onChange={(e)=>setId(e.target.value)}  placeholder="PAN Number"/>
                    <button onClick={()=>VerfiyDocs()} className='btn btn-primary mt-5 w-100'>Verify</button>
               </div> 
            </>:
            <>
            </>
          }
                
          </>
      }
          
      </>
  )
};

export default UploadAadhaarKycModule;
