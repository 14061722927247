// esignature page

export default function DocumentInfoModule (props) {

  const data = props.data;

  return (
  <>
    <div className="text-center">
      <p className="small text-tone m-0">DIN: {data}</p>
    </div>

  </>
  )
}