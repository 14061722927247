// esignature page
import { useRef, useState } from 'react';
import { Modal, Button } from "react-bootstrap";

import FormNeeded from '../webb/form-needed';

import { CreatePasscode ,CheckPasscode } from '../../services/srvc-esin-realm';
import '../../App.css'
import SignaturePad from 'react-signature-canvas'
export default function UserEsinMailModule (props) {


  const data = props.data;
  const features= props.feature
  console.log(features)
  const enable=props.enable

  const [send, setSend] = useState(false);
  const [chck, setCheck] = useState(false);
  const [signstr,setSignstr]=useState('')
  const [show, setShow] = useState(false);
  const [code, setCode] = useState('');
  const [save,setSave]=useState(false)
  const [canvas,setCanvas]=useState(true)
  const [font,setFont]=useState(false)
  const [typedname,setTypedname]=useState('')

  const [trxn,setTrxn]=useState()
 
  let signRef=useRef({})


  
  const CreateCode = async () => {
     console.log (data.emid)
     setSend(true);
    const res = await CreatePasscode ({email:data.emid, name:data.name});
    console.log(res)
    setTrxn(res.data.trxn)
    
    
  }

  const CheckCode = async () => {
    var obj={email:data.emid, otp:code.toString(),trxn:trxn}
    console.log(obj)
    const res = await CheckPasscode (obj)
    console.log(res)
    if(features.both)
    {
      if (res.data===true) {
        setCheck({text: 'Congratulations! Passcode Verified.', colr: 'text-success'})
        props.esin({"stat":true,"value":signstr, "name":typedname});
      } 
      else {
        setCheck({text: 'Passcode Incorrect. Please try again.', colr: 'text-danger'})
        props.esin({"stat":false, "value":""});
  
      }
    }
    if(features.only){
      if (res.data===true) {
        setCheck({text: 'Congratulations! Passcode Verified.', colr: 'text-success'})
        props.esin({"stat":true,"value":'', "name":'APPROVED'});
      } 
      else {
        setCheck({text: 'Passcode Incorrect. Please try again.', colr: 'text-danger'})
        props.esin({"stat":false, "value":""});
  
      }
    }
    
    // props.esin({"stat":true,"value":signstr, "name":typedname});
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const clearSign=async()=>{
    signRef.current.clear()
}

const saveSign=async()=>{
  if(signRef.current!=null){
  setSignstr(signRef.current.toDataURL()) 
  }

  if(typedname!=''){  
    setSignstr('')
  } 
    setSave(true)
   
    CreateCode() 
  
  }
  // console.log(signstr)


  const ApproveInvoice=async()=>{
    var datx={

    }
    //var res= await
  }

  return (
  <>
    <div className="rounded border bg-white">
      <div className="d-flex p-3">
        <div className="me-auto">
          <p className="">Name</p>
          <p className="m-0 p-0">Email</p>
        </div>
        <div className="text-end">
          <p className="">{data.name || '***'}</p>
          <p className="m-0 p-0">{data.emid || '***'}</p>
        </div>
      </div>

      <div className="border-bottom"></div>

      
      {/* code block 1 */}
      {
         send==false?
        <>
           <div className={`p-3 text-center}`}>
        <div className="d-flex">
          <div className="me-auto text-start" style={{width:'60%'}}>
            <p className="small m-0">Click Get OTP to continue.
              Submit OTP in the next step to complete your E-Signature.
            </p>
           
          </div>
          <div className="text-end">
            {
              features.only?
              <>
              <p style={{cursor:"pointer"}} className={`text-primary`} variant="primary" onClick={()=>{setSave(true);CreateCode()}} >
                Go For Direct Approval
              </p>
              </>:
              <>
              <p style={{cursor:"pointer"}} className={`text-primary`} variant="primary" onClick={handleShow}>
                Go For Sign & Approve
              </p>
              </>
            }
            
          
      

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title className="small m-0">CAPTURE SIGNATURE</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {
                  save==false && canvas==true?
                  <>
                
              <SignaturePad
                canvasProps={{width:450, height:250}}
                ref={signRef}
                penColor="blue"
              />
              <hr/>
              <div className="py-3"></div>
              
                 </>
              :
              <>
              {
                save==false && font==true?
                  <>
                  <div className="text-center " style={{width:"100%", height:"256px", backgroundColor:"white"}}>
                    <div className="py-3"></div>
                    <button className="hr1 btn text-primary " style={{fontSize:"33px"}}>{typedname}</button>
                    <hr/>
                    
                  </div>
                  </>
                  :
                  <>
                  </>
              } 

              </>
                }
              </Modal.Body>
              <Modal.Footer>
              {
                  font?
                  <>
                  <input className='form-control border-primary' placeholder='type.....' value={typedname} onChange={(e)=>setTypedname(e.target.value)} />
                  </>:
                  <>
                  </>
                }
                <div className="d-flex" style={{marginRight:"", position:""}}>
                  <Button className="" variant="btn btn-outline-primary" onClick={()=>{setCanvas(true);setFont(false)}}>
                      Sign
                    </Button>
                    <Button className="mx-3" variant="btn btn-outline-primary" onClick={()=>{setFont(true);setCanvas(false);setSignstr('')}}>
                      Type
                    </Button>
                </div>
               
                {
                  font==false?
                  <>
                  
                <Button variant="btn btn-outline-danger" onClick={clearSign}>
                  Clear
                </Button>
                <Button variant="btn btn-outline-success" onClick={()=>{saveSign();handleClose()}}>
                  Save
                </Button>
                </>
                :
                <>
                
                <Button disabled={typedname==''?true:false} variant="btn btn-outline-success" onClick={()=>{saveSign();handleClose()}}>
                  Save
                </Button>
                </>
                }      
                
              </Modal.Footer>
            </Modal>
         
            {/* <button
              className="btn btn-primary btn-sm px-3 rounded-pill"
              onClick={()=> CreateCode()}
              ><small>Get OTP</small>
            </button>          */}
            
          </div>
        </div>
      </div>
        </>:
        <>
        </>
      }
   

      

      {/* code input */}
      {
        send==true?
        <>
        <div className={`p-3 text-center }`}>
        <div className="form-group">
          <label className="form-label small">Enter OTP / Passcode <FormNeeded/></label>
          <input type="text" 
            disabled={enable}
            className="form-control height-md bg-lite"
            style={{fontSize:'0.9rem', height:'2.7rem', textAlign:'center'}}
            value={data.code}
            onChange={({ target }) => {setCode(target.value); setCheck({})}}
            placeholder="123456">
          </input>
        </div>
      </div>
        </>:
        <>
        </>
      }
      

      {/* code status */}
      <div className={`px-3 mb-3 text-center ${data.stat==0 && send ? '' : 'd-none'}`}>
        <p className={chck.colr}>{chck.text}</p>
      </div>


      {/* code action */}
      <div className={`px-3 mb-3 text-center ${data.stat==0 && send ? '' : 'd-none'}`}>
        <div className="d-flex">
          <div className="me-auto">
            <button
              className="btn btn-outline-secondary border-none btn-sm px-0 rounded-pill"
              onClick={()=> CreateCode()}
            ><small>Resend OTP</small></button>
          </div>
          <div className="text-end">

            <button
              className="btn btn-primary btn-sm px-3 rounded-pill"
              // disabled={code.length!==6 || isNaN(code)}
              onClick={()=> CheckCode()}
              ><small>Submit</small>
            </button>
          </div>
        </div>
      </div>

    </div>

  </>
  )
}