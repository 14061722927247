import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { GetDocumentInfo, getKycTransactionData, saveKYCDocs, saveUserAction, uploadDocs, VerifyBusinessPAN, VerifyUserPAN } from '../../services/srvc-esin-realm';
import UserEsinDone from '../esin/user-esin-done';
import WebbLoaderIcon from '../webb/webb-loader-icon';
import BasicInfoModule from './basic-info';
const CollectKYCBDocsModule = () => {
    const [type,setType]=useState()
    const [file,setFile]=useState()
    const [data,setData]=useState([])
    const [id,setId]=useState('')
    const [member,setMember]=useState()
    const [loading,setLoading]=useState(true)
    const [compkyc,setCompkyc]=useState(true)
    const [compfile,setCompfile]=useState()
    const [comppan,setComppan]=useState()
    const [compname,setCompname]=useState()
    const [compres,setCompres]=useState()


    const [resstatus,setResstatus]=useState('')

    const history = useHistory()
    

    const enid=useParams().enid
    const usid=useParams().usid

    // console.log(enid , "+", usid)


    function getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }

    useEffect(()=>{

        const fetchdata= async()=>{
            setLoading(true)
            const docx = await getKycTransactionData({data:{enid:enid}})
            console.log(docx.data)
            setData(docx.data)
            // setMember (docx.data.lstb.find(item => item.enid === usid))

            setLoading(false)
        }

        fetchdata()

    },[enid])



const VerfiyCompDocs=async()=>{
  if(type=="" || compfile==null || compname=="")
  {
      window.alert("kindly fill All fields")
  }
  else
  {
      setLoading(true)
      
      
      setComppan(comppan.toUpperCase())

      var id=comppan.toUpperCase()

      // console.log()

      if(id =="CONCT1234X")  // valid
      {

        var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded Company KYC Document for Verification with Company PAN no. ${id} and Company Name ${compname} </p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
        await saveUserAction(JSON.stringify(dbj))

        var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Company KYC Document Verified With Company Name ${compname} and Company PAN No. ${id}</p> `,"document":data.clnt.trid,date:new Date()},"user":data.usid}
        await saveUserAction(JSON.stringify(dbj))

        var result = {
            message: "Verification completed with positive result",
            upstreamName : compname,
            verified: true
        }

        // console.log(result)
        setCompres(result)

        setCompkyc(false)

      }

      if(id == "CONCT1234Y")  // negative
      {
        var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded Company KYC Document for Verification with Company PAN no. ${id} and Company Name ${compname}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
        await saveUserAction(JSON.stringify(dbj))

        var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Company KYC Document Could Not Verified </p>`,"document":data.clnt.trid,date:new Date()},"usid":data.usid}
        await saveUserAction(JSON.stringify(dbj))

        
        var result = {
          message: "Verification completed with Negative result",
          upstreamName : compname + "XYZ",
          verified: false
         }

          // console.log(result)
          setCompres(result)

          setCompkyc(false)

      }

      if(id == "CONCT1234Z")  // failed
      {
        var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Uploaded Company KYC Document for Verification with Company PAN no. ${id} and Company Name ${compname}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
        await saveUserAction(JSON.stringify(dbj))

        var result = {
          message: "Invalid Pan Card Number",
          upstreamName : "Invalid",
          verified: false
         }

          // console.log(result)
          setCompres(result)

          setCompkyc(false)

      }


      if(id != "CONCT1234X" && id != "CONCT1234Y" && id != "CONCT1234Z")
      {

            var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo:  ${data.name} Uploaded Company KYC Document for Verification with Company PAN no. ${id} and Company Name ${compname}</p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
            await saveUserAction(JSON.stringify(dbj))
            
            const result= await VerifyBusinessPAN({data:{nmbr:comppan,name:compname},user: usid})
            // console.log(result)
            if(result.verified==true)
            {

              var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Company KYC Document Verified With Company Name ${result.data} and Company PAN No. ${comppan} </p>`,"document":data.clnt.trid,date:new Date()},"user":data.usid}
              await saveUserAction(JSON.stringify(dbj))

            }
            if(result.verified==false)
            {

              var dbj={data:{memo:`<p> Document Id: ${data.clnt.nmbr}<br/>Memo: ${data.name} Company KYC Document Could Not Verified</P>`,"document":data.clnt.trid,date:new Date()},"usid":data.usid}
              await saveUserAction(JSON.stringify(dbj))
            }
          
              setCompres(result)
              
            setCompkyc(false)


      }
      
      
      
      
      setLoading(false)

  }
}

const UploadkycDocs=async()=>{
  setLoading(true)
  
  var compndata
  var userdata
  if(compfile!=null)
  {
    const str2=await getBase64(compfile)
    // console.log(str2)
    compndata={
      "file":str2,
      "type":"bznx",
      "sort":type,
     }
  }



  var payload={
    "data":{
      "file":compndata.file,
      "document":data.clnt.trid,
      "trxn":enid,
      "format":"kyc",
      "sort":"bznx-panc",
      "data":{
        "name":compname,
        "id": comppan,
        "verf":compres.verified,
        "vrnm":compres.upstreamName
        }

    },
    "user":data.admn[0].usid
  }

  const res= await saveKYCDocs(payload)
  //  console.log(res)
   
  history.push(`/`)
  // window.location.href="https://esnx.contrato.ws/"
}

if(data && data.stat=="6")
{
  return(<> <UserEsinDone /> </>)
}

if(loading){
  return (<> <WebbLoaderIcon /> </>)
}

  return(
      <>
      
      {
        compkyc?
        <>
        <div className='mt-5 border p-5 bg-white' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"600px"}}>

               <h6 className='text-center mb-2'>{data.enid}</h6>

                <BasicInfoModule data={data}  />

               <hr className="mb-5"/>

                    <p className=' fw-bold text-center text-primary'>Upload Company's Document For Verification</p>
                    <hr/>
                    <div className='d-flex'>
                    <select onChange={(e)=>setType(e.target.value)} className='mx-1' style={{width:"50%"}}>
                        <option>Choose Document Type</option>
                        {/* <option value={"adhr"}>Adhaar Card</option>
                        <option value={"drvl"}>Drivery Liecence</option> */}
                        <option value={"panc"}>Pan Card</option>
                        {/* <option value={"othr"} >Other Govt. Card</option> */}
                    </select>
                    
                    <input accept='image/png , image/jpeg' onChange={(e)=>setCompfile(e.target.files[0])} className='form-control' type={"file"} />
                    </div>
                    <input value={comppan} onChange={(e)=>setComppan(e.target.value)} className='form-control mt-3' type={"text"} placeholder="Company PAN Number" />
                    <input  className='form-control mt-3' type="text" value={compname} onChange={(e)=>setCompname(e.target.value)}  placeholder="Company Name"/>
                    <div className='d-flex'>
                      <div className='me-auto'></div>
                      <div className='text-end'><button onClick={()=>{setComppan("CONCT1234X");setCompname(data.name)}} className='btn btn-sm btn-warning back-color-next border-none rounded-pill px-3 mt-3'>Use Test Credential</button></div>

                    </div>
                    <button onClick={()=>VerfiyCompDocs()} className='btn btn-primary mt-5 w-100'>Verify</button>
               </div> 
        </>:
        <>
        <div className='mt-5 border p-5' style={{maxWidth:"650px", marginLeft:"auto", marginRight:"auto", height:"180px"}}>

            <p className={`fw-bold text-center ${compres.verified==true?'text-success':'text-danger'}`}>{compres.verified==true?"Verification Completed SuccessFully":"Wrong Credential... Verification UnsuccessFull"}</p>
            <p className='fw-bold text-center text-dark'>Company Name As Per ID:- {compres.upstreamName}</p>
            <p className='text-center text-dark'>Company Name on Document will be:- {compres.upstreamName}</p>
            <hr/>

            {
              compres.verified==true?
              <>
              <button onClick={()=>UploadkycDocs()} className='btn btn-primary mt-5 w-100'>Next</button> 
              
              </>:
              <>
              <button onClick={()=>setCompkyc(true)}  className='btn btn-primary mt-5 w-100'>Go Back</button>
              </>
            }

      </div>
        </>

      }

     
          
      </>
  )
};

export default CollectKYCBDocsModule;
