// esignature page
import { Document,Page } from 'react-pdf';
import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
// import { StyleSheet, Page, Document} from '@react-pdf/renderer';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;





export default function DocumentFileModule (props) {
  const data = props.data;
  const view= props.view
  const signed=props.signed
  

  const [scale, setScale] = useState(1.29);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

 
  
  
  return (
  <>
    {/* <div className="rounded border p-1 bg-white">
      <embed 
        style={{width:"100%",height:"100vh"}} 
        src={`data:application/pdf;base64,${data}`} 
        type="application/pdf" 
      />
    </div> */}
    <div>

    {
      view==true?
      <>
        {
          signed?
          <>
            <div style={{maxWidth:"760px", marginLeft:"auto", marginRight:"auto", height:"100px", backgroundColor:"#C1FFAE"}}>
            <h2 className='text-center text-dark my-auto mt-3 p-3'>Congratulations...</h2>
            <h4 className='text-center text-dark mt-1' >Your signature has been captured.</h4>

          </div>
          </>
          :
          <>
          </>
        }
        
        <div className='border border-dark mt-4'>

            <embed 
            style={{width:"100%",height:"100vh"}} 
            src={`data:application/pdf;base64,${data}`+ "#toolbar=0"} 
            type="application/pdf" 
          />
        {/* <Document
        
          file={`data:application/pdf;base64,${data}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
        </div>

        <div className='mb-3' style={{maxWidth:"400px", marginLeft:"auto", marginRight:"auto"}}>
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          file={`data:application/pdf;base64,${data}`}
        /> */}
        </div>
        </>
       
      :
      <>
        <div style={{maxWidth:"760px", marginLeft:"auto", marginRight:"auto", height:"100px", backgroundColor:"#C1FFAE"}}>
        <h2 className='text-center text-dark my-auto mt-3 p-3'>Congratulations...</h2>
        <h4 className='text-center text-dark mt-1' >You Have Successfully Approved The Document/Invoice</h4>

      </div>
      </>
    }
    
    
       
    </div>


  </>
  )
}