import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

//import WebbLoader from "../../webb/webb-loader";
import WebbDividerMedium from "../webb/webb-divider-md";
import WebbDividerSmall from "../webb/webb-divider-sm";

import { SetEsinUser } from "../../services/srvc-auth-user";
import { CheckPasscode, CreatePasscode, GetDocumentInfo, saveUserAction } from "../../services/srvc-esin-realm";
import WebbLoaderIcon from "../webb/webb-loader-icon";
import UserEsinDone from "../esin/user-esin-done";

export default function AuthMailInvoiceModule () {
  const {enid,usid}=useParams()
console.log("enid"+enid+"usid"+usid)
  // const user=props.member
  const [data,setData]=useState([])
  const [username, setUsername] = useState()
  const [passcode, setPasscode] = useState("");
  const [btnPasscode, setButtonPasscode] = useState(true);
  const [btnLogin, setButtonLogin] = useState(false);
  const [text, setText] = useState("");
  const [code, setCode] = useState(false);
  const [verf, setVerif] = useState(false);
  const [mmbr,setMember]=useState()
  const [trxn,setTrxn]=useState()
  const [loading,setLoading]=useState(true);
  const history = useHistory();

  // const refreshPage = ()=> {
  //   window.location.reload(false);
  // }

  // const handleSetup = async() => {
  //   window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
  //     'size': 'invisible',
  //     'callback': (response) => {

  //       setVerif(true);
  //       handlePasscode();
  //     }
  //   }, auth);
  // }

  useEffect(()=>{
    setLoading(true)
    const fetchdata=async()=>{
      console.log("enid"+enid)
      const docx = await GetDocumentInfo(enid)
        console.log(docx)
      if(docx && docx.feat.auth==false)
      {
        var person=docx.list.find(item => item.enid == usid)
        
        var dbj={data:{memo:`${person.name} has Opened The Invitation Link`,"document":docx.clnt.trid,date:new Date()},"user":person.usid}
        await saveUserAction(JSON.stringify(dbj))
        history.push(`/esma/invc/${enid}/${usid}`)
        
      }
      else
      {
        setData(docx)
        console.log("setting member")
        var person=docx.list.find(item => item.enid == usid)
        console.log(person)
        setMember (docx.list.find(item => item.enid == usid))

        var dbj={data:{memo:`${person.name} has Opened The Invitation Link`,"document":docx.clnt.trid,date:new Date()},"user":person.usid}
        await saveUserAction(JSON.stringify(dbj))
      }
      // setLoading(false)
      
    }
    fetchdata()

  },[usid])


  // console.log(mmbr)

 
  useEffect(()=>{
    setLoading(true)
    if(mmbr)
    {
      
      if(data.feat.auth==true)
      {
        var authuser=JSON.parse(localStorage.getItem("auth"))
        if(authuser.usid==usid && authuser.verf==true)
        {
        history.push(`/esma/invc/${enid}/${usid}`)
        }
      }
    }
    setLoading(false)

  },[data])

  const handlePasscode = async () => {
    setText(`we have send verification OTP to ${mmbr.emid}`)
    const res=await CreatePasscode({email:mmbr.emid, name:mmbr.name})
    console.log(res)
    setTrxn(res.data.trxn)
    setButtonPasscode(false)
    setButtonLogin(true)
   
    
   
  }

  const resendPasscode = async() => {

  }

  const handleSubmit = async e => {
    e.preventDefault();    
    localStorage.clear();
    
    if (username==="" || passcode==="" ) {
      setText('Mobile Number and OTP should not be empty');
    } else {
      
        setLoading(true);
        var obj={email:mmbr.emid,otp:passcode.toString(),trxn:trxn}
        // console.log(obj)
         const check=await CheckPasscode(obj)
        // console.log(check)
        //  console.log("authorised")
         
        //  await saveUserAction({"text":`${mmbr.name} has Logged In For Signing Document`,"user":usid,"enid":enid,"dcid":data.clnt.trid,"webb":data.clnt.webb})
         //history.push(`/next/${enid}/${usid}`)
        //  history.push(`/next/${enid}/${usid}`)
         if(check.data==true)
        {
          localStorage.setItem("auth",JSON.stringify({"usid":mmbr.enid,verf:"true"}))
          console.log("authorised")
          localStorage.setItem("auth",JSON.stringify({"usid":mmbr.enid,verf:"true"}))

          history.push(`/esma/invc/${enid}/${usid}`)

          //history.push(`/next/${enid}/${usid}`)
          // history.push(`/kyc/upload/docs/${enid}/${usid}`)
          // props.autho({value:true})
        }
        if(check.data==false)
        {
          setText("OTP not matched, Kindly re-enter OTP")
          console.log("not authorised")
          
        }
        // 1 - check Passcode
        // SetEsinUser - Local storage
        // 2 - redirect to signing UI

        setLoading(false);
    }
  };
  if (mmbr!=null && mmbr.stat===1)  return ( <> <UserEsinDone /> </> )
  if (loading) 
  return (<> <WebbLoaderIcon /> </>)

  return (
  <>
  
    <WebbDividerMedium />
    <div className="d-none d-md-block" style={{height:'15vh'}}></div>
    
    {/* main */} 
    <form onSubmit={handleSubmit}>
    <div className="row">
      <div className="col-3 d-none d-md-block"></div>
      <div className="col">
        
       
        <WebbDividerSmall />
      {
        mmbr!=null?
        <>
         <p className="lead fw-bold text-color-main m-0">E-Signatures</p>
        <p className="fw-bold text-tone">Please Verify Your Email to Continue</p>
         <div className="form-group mb-3">
          <label className="form-label small">Email <span className="text-danger">*</span></label>
          <input type="text" 
            className="form-control height-md" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            disabled={btnLogin}
            value={mmbr.emid}
            
            // onChange={({ target }) => {setUsername(target.value); setText('');}}
            placeholder="9876540123">
          </input>
        </div>
        <div className="form-group mb-3">
          <label className="form-label small">Passcode (OTP) <span className="text-danger">*</span></label>
          <input type="password" 
            className="form-control height-md" 
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            disabled={btnPasscode}
            value={passcode}
            onChange={({ target }) => {setPasscode(target.value); setText('');}}
            placeholder="Enter Passcode">
          </input>
        </div>
        
        {/* action */}
        <div className={`mb-3 d-grid ${btnPasscode? '' : 'd-none'}`}>
          <button type="button"
            // disabled={username===''|| username.length!==10}
            className="btn btn-primary back-color-main height-md"
            onClick={()=>handlePasscode()}
            ><small>Get Passcode</small>
          </button>
        </div>
        <div className={`mb-3 d-grid ${btnLogin? '' : 'd-none'}`}>
         
         <button type="submit" 
          //  disabled={passcode==='' || passcode.length!==6}
           className="btn btn-primary back-color-main height-md">
           <small>Login</small>
         </button>
       </div>
        <div className="row">
      <div className="col-3 d-none d-md-block"></div>
      <div className=""></div>
        <small className="text-danger">{text}</small>
      <div className="col">
        <div className="d-flex">
          <div className="me-auto">
            <button 
              className="btn btn-link text-decoration-none p-0 text-muted"
              onClick={()=>resendPasscode()}
            ><small>Resend OTP</small></button>
            <div className="pb-3"></div>
          </div>
          
          <div className="">
          </div>

        </div>
      </div>
      <div className="col-3 d-none d-md-block"></div>

    </div>
    
        </>:
        <>
         <WebbLoaderIcon />
        </>
      }
      
      </div>
      <div className="col-3 d-none d-md-block"></div>
      </div>
    </form>  
    
    {/* resend code */}
    
    
  </>
  )
}