import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

//views - main
import Main from '../viewsxz/main';
import Land from '../viewsxz/land';

import Signature from "../components/Signature";

import DocumentEsinMail from "../viewsxx/document-esin-mail";
import AuthMailCustomModule from "../content/auth/auth-mail-custom";
import NextMailCustomModule from "../content/auth/next-mail-custom";
import UploadDocsModule from "../content/kyc/upload-docs";
import AuthMailInvoiceModule from "../content/auth/auth-mail-invoice";
import InvoiceViewModule from "../content/appr/esin-docx-view";
import InvoiceApprovalMail from "../viewsxx/invoice-esin-mail";
import AuthAadhaarCustomModule from "../content/auth/auth-adhr-custom";
import UploadAadhaarKycModule from "../content/kyc/kyc-adhr";
import CollectKYCUDocsModule from "../content/kyc/collect-kycu";
import CollectKYCBDocsModule from "../content/kyc/collect-kycb";
import DocumentViewModule from "../content/esin/esin-docx-view";
import AdhaarTestDocumentViewModule from "../content/testadhr/docx-view";
import DocumentEsinBulkMail from "../viewsxx/document-esin-bulk-mail";
import BulkAdhaarTestDocumentViewModule from "../content/bulk-esin-adhr/docx-view";


const routes = [

  { path:'/', component: Land, auth:false },
  { path:'/esin/:enid/:usid', component: Signature, auth:false },
  
  { path:'/esma/docs/:enid/:usid', component: DocumentEsinMail, auth:false },
  { path:'/auth/docs/:enid/:usid', component: AuthMailCustomModule, auth:false },
  { path:'/next/docs/:enid/:usid', component: NextMailCustomModule, auth:false },
  { path:'/kyc/upload/docs/:enid/:usid', component:UploadDocsModule, auth: false},

  { path:'/auth/invc/:enid/:usid', component: AuthMailInvoiceModule, auth:false },
  { path:'/esma/invc/:enid/:usid', component: InvoiceApprovalMail, auth:false },
  
  { path:'/auth/docs/main/adhr/:enid/:usid', component: AuthAadhaarCustomModule, auth:false },

  { path:'/auth/docs/test/adhr/:enid/:usid', component: AuthAadhaarCustomModule, auth:false },
  { path:'/esma/docs/test/adhr/:enid/:usid', component: AdhaarTestDocumentViewModule, auth:false },

  { path:'/bulk/esma/docs/:enid/:usid', component: DocumentEsinBulkMail, auth:false },
  { path:'/bulk/adhr/docs/:enid/:usid', component: BulkAdhaarTestDocumentViewModule, auth:false },


  { path:'/panc/kyc/indx/:enid/:usid', component: CollectKYCUDocsModule, auth:false },
  { path:'/panc/kyc/bznx/:enid/:usid', component: CollectKYCBDocsModule, auth:false },

]

const PrivateRoute = (props) => {
  const location = useLocation();
  //console.log (location.pathname)
  const { user } = useAuth();
  return user 
  ? ( <Route { ...props } /> ) 
  : ( <Redirect to={{ pathname: "/account/access", state: { from: location } }} /> )
};

export default function Routes() {

  return (
    <Switch>
      {routes.map ((item,i)=>(item.auth
      ? <PrivateRoute
          key={i}
          path={item.path}
          component={item.component}
          exact
        />
      : <Route
          key={i}
          path={item.path}
          component={item.component}
          exact
        />
      ))}
    </Switch>
  );
}