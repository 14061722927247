//date-time
export default function WebbDateTime() {
 
  return (
  <>
    <div className="text-center">
      <span className="small text-tone">
        {((new Date().toISOString().substr(0,10)))}
        {' - '}
        {((new Date().toISOString().substr(11,5)))}
        {' UTC '}
      </span>
    </div>
  </>
  )
}